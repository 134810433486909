.pearlbanner {
  width: 100%;
  margin-bottom: 1.4em;
  background: #ebb5b3;
  z-index: 100;
}

.pearllink {
  color: lightskyblue;
}

.pearl-box {
  border-radius: 25px;
  border: 2px solid #bbbbbb;
  padding: 5px;
}
.inputField {
  padding: 0.2em;
}
.pearl-input {
  padding: 0.2em;

  border-radius: 10px;
  border: 2px solid #bbbbbb;
  width: 80%;
}
.pearl-container {
  padding: 2em;
}

textarea:focus,
input:focus {
  outline: none;
}

.btn-pearlshare {
  border-radius: 8px;
  color: #fff;
  background-color: #f45336;
  border-color: #fff;
}
.pearl-padding-top {
  padding-top: 1.4em;
}
.pearl-center {
  margin: auto;
  text-align: center;
}

.btn-modal {
  text-align: left;
  margin: 0px 0px;
  padding: 0px;
}
.pearl-float-left {
  float: left;
  padding: 2px;
}
.pearl-table {
  width: 80%;
}
.button-pearl-border {
  border-radius: 8px;
}

.btn-pearlshare:hover {
  background-color: #ebb5b3;
  color: #f45336;
}
.pearl-brand {
  margin-left: 1rem;
}

h1,
h3,
h5 {
  font-family: Monsteratt;
  color: #f45336;
}

.pearl-primary-text {
  font-family: Monsteratt;
  color: #f45336;
  font-size: 1rem;
  font-weight: 300;
}

p {
  font-family: Monsteratt;
  font-size: medium;
  color: grey;
}

p.important {
  color: rgb(255,0,0);
}
.btn {
  text-transform: unset !important;
}
.body-pearl {
  background-color: #ffffff;
}

.header-pearl {
  text-align: center;
}
.main-pearl {
  padding-left: 2em;
}
table.pearlTable {
  margin-left: auto;
  margin-right: auto;
  border-spacing: 40px;
  border-collapse: separate;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.modal-box {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 60%;
  overflow-y: auto;
  background-color: #212529;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  padding: 40px;
}
label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}
input {
  margin: 0 0 10px;
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  padding: 6px 10px;
  box-sizing: border-box;
  outline: none;
  font-weight: 400;
  height: 32px;
  line-height: 1.3;
}
textarea {
  margin: 0 0 10px;
  color: #555;
  background-color: #fff;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  padding: 6px 10px;
  box-sizing: border-box;
  outline: none;
  font-weight: 400;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.Next {
  background-color: #155724;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.Next:hover {
  background-color: #c3e6cb;
}

.Next:disabled {
  cursor: not-allowed;
  background-color: #888;
}
.Back {
  background-color: #c77f27;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 3px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.Back:hover {
  background-color: #9e631b;
}
.entries {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
 }
 .entries td, #entries th {
  border: 1px solid #ddd;
  padding: 8px;
 }
 
 .entries tr:nth-child(even){
  background-color:#f2f2f2;
 }
 
 .entries tr:hover {
  background-color:#ddd;
 }
 
 .entries th{
  padding-top:12px;
  padding-bottom:12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
 }

 select {
  margin: 10px;
  width: 150px;
  
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  border: 1px solid #CCC;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;

 
}


/* CAUTION: Internet Explorer hackery ahead */


select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    select {
        background: none\9;
        padding: 5px\9;
    }
}